import Cookies from 'universal-cookie'

import { combineReducers } from 'redux'
import {
    LOADING,
    SET_NOTIFICATION,
    MODIFY_INTERFACE_PARAM
} from '../actions'

import {
    LOGGED_IN,
    LOGIN_FAILED,
    LOG_OUT,
    CHECK_LOGGED_IN,
    ASSERT_LOGGED_IN,
    REFRESH_TOKEN,
    SHOULD_REDIRECT_TO_LOGIN,
    SHOULD_REDIRECT_TO,
    SET_LOGIN_SCREEN_TYPE
} from '../actionsAuth'

import { 
    MODIFY_DATA_PARAM,
    EXPAND_CONVERSATION
} from '../actionsAPI'

function data(
    state = {
        profile: {},
        sessionID: "",
        translatedQuery: null,
        openJobID: null,
        conversation: [],

        shouldPostSearch: false,
        searchQuery: {query:"", startFrom:0},
        openDoc: null
    },
    action
) {
    switch (action.type) {
        case MODIFY_DATA_PARAM:
            var cs = Object.assign({}, state, {})
            cs[action.paramName] = action.paramValue
            if (action.shouldPostSearch)
                cs['shouldPostSearch'] = true
            return cs
        case EXPAND_CONVERSATION:
            var cs = Object.assign({}, state, {})
            //cs["conversation"].push (...action.newComs)
            cs["conversation"] = action.newComs
            return cs
        default:
            return state
    }
}

function auth(
  state = {
    auth: null,
    username: "",
    loggedIn: false,
    shouldRedirectTo: null
  },
  action
) {
    const cookies = new Cookies();
    switch (action.type) {
        case SHOULD_REDIRECT_TO_LOGIN:
            return Object.assign({}, state, {
                shouldRedirectTo: '/login'
            })    
        case SHOULD_REDIRECT_TO:
            return Object.assign({}, state, {
                shouldRedirectTo: action.to
            })    
        case CHECK_LOGGED_IN:
            var loggedIn = false
            var vauth = cookies.get("sysauth")
            if ((vauth !== undefined) && (vauth !== null))
            {
                var at = vauth.access_token
                if ((at) && (at.length > 5) && (at !== undefined) && (at !== null))
                  loggedIn = true
            }
                
            return Object.assign({}, state, {
                loggedIn: loggedIn,
                username: cookies.get("sysusername"),
                //auth: vauth,
            })

        case ASSERT_LOGGED_IN:
            var loggedIn = false
            var vauth = cookies.get("sysauth")
            if ((vauth !== undefined) && (vauth !== null))
            {
                var at = vauth.access_token
                if ((at) && (at.length > 5) && (at !== undefined) && (at !== null))
                    loggedIn = true
            }
                
            return Object.assign({}, state, {
                loggedIn: loggedIn,
                username: cookies.get("sysusername"),
                shouldRedirectTo: loggedIn ? null : '/login'
                //auth: vauth,
            })

        case LOGGED_IN:
            cookies.set('sysauth', action.auth, { path: '/' })
            cookies.set('sysusername', action.username, { path: '/' })
            
            return Object.assign({}, state, {
                loggedIn: true,
                username: action.username,
                auth: action.auth,
                shouldRedirectTo: null
            })
        case REFRESH_TOKEN:
            cookies.set('sysauth', action.auth, { path: '/' })
            
            return Object.assign({}, state, {
                auth: action.auth,
                shouldRedirectTo: null
            })
        
        case LOGIN_FAILED:
            cookies.set('sysauth', null, { path: '/' })
            cookies.set('sysusername', "", { path: '/' })
            return Object.assign({}, state, {
                loggedIn: false,
                username: "",
                auth: null,
                shouldRedirectTo: '/login'
            })
        case LOG_OUT:
            cookies.set('sysauth', null, { path: '/' })
            cookies.set('sysusername', "", { path: '/' })
            return Object.assign({}, state, {
                loggedIn: false,
                username: "",
                auth: null,
                profile: {}
            })
        default:
            return state
    }
}

function interfaceParams(
    state = {
        loading: false,
        loadingProgress: null,
        notificationHeader: null,
        notificationContent: null,
        notificationClass: null,
        loginScreenType: 'login',
        browsingDocId: null,
    },
    action
) {
    switch (action.type) {
        case SET_LOGIN_SCREEN_TYPE:
            return Object.assign({}, state, {
                loginScreenType: action.screenType
            })
        case LOADING:
            if (action.isLoading)
                    return Object.assign({}, state, {
                        notificationHeader: null,
                        notificationContent: null,
                        notificationClass: null,
                        loading: action.isLoading,
                        loadingProgress: action.isLoading ? action.loadingProgress : null
                    })
                else
                    return Object.assign({}, state, {
                        loading: action.isLoading,
                        loadingProgress: action.isLoading ? action.loadingProgress : null
                    })
        case SET_NOTIFICATION:
            return Object.assign({}, state, {
                notificationHeader: action.header,
                notificationContent: action.content,
                notificationClass: action.nclass,
            })
        case MODIFY_INTERFACE_PARAM:
            var cs = Object.assign({}, state, {})
            cs[action.paramName] = action.paramValue
            return cs
        default:
            return state
  }

}

export default combineReducers({
    auth,
    interfaceParams,
    data
})