import React, { Component } from 'react';
import { connect } from 'react-redux'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';

import zxcvbn from 'zxcvbn'

import {
    resetPass
} from '../redux/actionsAuth'

class ResetPass extends Component
{
    state = {
        password:"",
        cpassword:"",
        pScore: {score:0}
    }

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
        if (e.target.name === 'password')
            this.setState ({pScore: zxcvbn(e.target.value)})
      };

    handleSubmit = e => {
        e.preventDefault();    
        this.props.dispatch(resetPass (this.state.password, this.props.secKey))        
    }

    render() {
        var passFilled = this.state.password && (this.state.password.length > 4) && this.state.cpassword && (this.state.password === this.state.cpassword)
        
        return (
            <div className="login h-100 d-flex align-items-center justify-content-center">
                <div className="loginForm">
                    <h3 className='mb-3'>Reset password at M42D</h3>
                    <div>
                        <form method="post" onSubmit={this.handleSubmit}>
                            <div className="mb-3">
                                <div className="control">
                                    <label htmlFor="password" className="form-label">Password</label>
                                    <input
                                        className="form-control"
                                        name="password"
                                        required
                                        onChange={this.handleInputChange}
                                        value={this.state.password}
                                        type="password"
                                    />
                                    <span className={"passwordStrength ps" + this.state.pScore.score}><span className="p1"><span/></span><span className="p2"><span/></span><span className="p3"><span/></span><span className="p4"><span/></span></span>
                                    {this.state.pScore.feedback && this.state.pScore.feedback.warning ? <span className="passwordHint">{this.state.pScore.feedback.warning}</span> : null}
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="control">
                                    <label htmlFor="password" className="form-label">Confirm Password</label>
                                    <input
                                        className="form-control"
                                        name="cpassword"
                                        required
                                        onChange={this.handleInputChange}
                                        value={this.state.cpassword}
                                        type="password"
                                    />
                                </div>
                            </div>

                            <div className="control">
                                <button type="submit"   className={"float-right btn btn-primary float-end " + (passFilled ? "" : " disabled")} disabled={!passFilled} >Reset</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>       
        );
    }
}

function mapStateToProps(state) {
    return {
        loading: state.interfaceParams.loading,
    }
}

export default connect(mapStateToProps)(ResetPass)
