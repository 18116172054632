import { Component, createRef } from "react";
import { connect } from 'react-redux'
import Table from 'react-bootstrap/Table';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';

import { apiRequest, MODIFY_DATA_PARAM } from "../redux/actionsAPI";
import { MODIFY_INTERFACE_PARAM } from "../redux/actions";
import Button from "react-bootstrap/esm/Button";

import { ReactComponent as LinkIcon } from '../img/ic_out.svg'
import { ReactComponent as SearchIcon } from '../img/ic_search.svg'
import ChatWindow from "./ChatWindow";

class DocViewer extends Component {
    state = {
        
    }

    constructor(props) {
        super(props)
        this.myRef = createRef()   // Create a ref object 
    }
    

    componentDidMount() {
        //this.props.dispatch (apiRequest({'endpoint': 'startSession'}))
    }

    componentDidUpdate (prevProps) {
        if (this.props.interface.browsingDocId && ((!prevProps.interface.browsingDocId) || (this.props.interface.browsingDocId !== prevProps.interface.browsingDocId))) {
            this.props.dispatch (apiRequest({'endpoint': 'docDetails', 'query':this.props.searchQuery, 'ref':this.props.interface.browsingDocId }, null, false))
            if (this.myRef.current)
                this.myRef.current.scrollTo(0, 0);

            //this.props.dispatch({type: MODIFY_DATA_PARAM, "paramName": "translatedQuery", "paramValue":null})
        }
    }

    onKeyUp(event) {
        if (event.keyCode === 13) {
            var that = this
            this.props.dispatch (apiRequest({'endpoint': 'chatRequest2', 
                                            'query':this.state.chatQuery, 
                                            'sessionID':this.props.data.sessionID, 
                                            'docRefs': this.props.data.docs.map (function (p) { if (!that.state.unselectedRefs[p.ref]) return p.ref; else return null }),
                                        }))
            //this.setState ({chatQuery:""})
        }
    }

    heatmapColor (pHMValue) {
        return 'rgba(' + (255 - Math.floor(169 * pHMValue)).toString() + ',' + (255 - Math.floor(80 * pHMValue)).toString() + ',' + (255 + Math.floor(0 * pHMValue)).toString() + ',' + (1 + 0.*pHMValue).toString() + ')'
    }

    render () {
        var that = this
        if (this.props.openDoc === null)
            return null

        var doc = this.props.openDoc

        var mdSections = []
        var pDics = doc.pDics.map (function (par, ip) {
            var pID = "par" + ip
            if (par.isMDpar)
                mdSections.push ([par, pID])
            return <div key={'par' + ip} className="paragraph" id={pID}>
                <div className="relBar"><span className="hmbar" style={{background:that.heatmapColor(par.value ? par.value : 0)}}></span></div>
                <div className="actions">
                    <Button onClick={(e)=>(that.props.dispatch({type: MODIFY_DATA_PARAM, "paramName": "searchQuery", "paramValue": Object.assign({}, that.props.searchQuery, {'query':par.content, 'startFrom':-1}), shouldPostSearch: true}))} ><SearchIcon/></Button>
                </div>
                <div className="section">{par.sectionParNum}{par.isContentPar ? ("(" + par.contentParNum + ")"):null}</div>
                <div className="content">{par.content}</div>
            </div>
        })

        
        
        return <div className={"DocViewer "} ref={this.myRef} >
            <div className="header">
                <h4 className="caption">{doc.caption}</h4>
                <div className="fields">
                    <div className="date">{doc.dateString}</div>
                    <div className="source"><a href={doc.link} target="blank">{doc.source.name} <LinkIcon/></a></div>
                    <div className="language">{doc.language}</div>
                </div>
                {mdSections.length > 0 ? <div className="mdSelector">
                    <h5>Links to market definition sections:</h5>
                    {mdSections.map (function (mdS, ic) {
                        return <a key={"mdl" + ic} href={"#" + mdS[1]}>{mdS[0].sectionParNum} {mdS[0].content }</a>
                    })}
                </div>:null}
            </div>
            <div className="contents">
                {pDics}
            </div>
            
            <ChatWindow />

        </div>
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        profile: state.data.profile,
        openDoc: state.data.openDoc,
        searchQuery: state.data.searchQuery
    }
}

export default connect(mapStateToProps)(DocViewer)