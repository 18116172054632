import { Component, createRef } from "react";
import { connect } from 'react-redux'
import Table from 'react-bootstrap/Table';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';

import { apiRequest, MODIFY_DATA_PARAM } from "../redux/actionsAPI";
import { MODIFY_INTERFACE_PARAM } from "../redux/actions";
import Button from "react-bootstrap/esm/Button";

import { ReactComponent as LinkIcon } from '../img/ic_out.svg'
import { ReactComponent as SearchIcon } from '../img/ic_search.svg'

import { ReactComponent as ChatIcon } from '../img/chat-outline.svg'

import { 
    getAccessToken
 } from "../redux/actions"

import { GLOBAL_URL } from "../redux/actionsAuth"

class ChatWindow extends Component {
    state = {
        currentQuery: "",
        waitingForResponse: false,
        generatedText: "",
        dialog: [],
        minimised: true
    }

    constructor(props) {
        super(props)
    }
    

    componentDidMount() {
        
    }

    componentDidUpdate (prevProps) {
        if (this.props.interface.browsingDocId && ((!prevProps.interface.browsingDocId) || (this.props.interface.browsingDocId !== prevProps.interface.browsingDocId))) {
            this.props.dispatch (apiRequest({'endpoint': 'docDetails', 'query':this.props.searchQuery, 'ref':this.props.interface.browsingDocId }, null, false))
            this.setState ({generatedText: "",
                dialog: [], currentQuery:""})
            
            //this.props.dispatch({type: MODIFY_DATA_PARAM, "paramName": "translatedQuery", "paramValue":null})
        }
    }

    handleSubmit (e) {
        //async () => {
            var that = this
            that.setState ({generatedText: "", waitingForResponse: true})
            const conf = {
                method: "post",
                body: JSON.stringify({ "query": that.state.currentQuery, "docID": that.props.openDoc.ref, "dialog":that.state.dialog }),
                headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
              };
            return fetch(GLOBAL_URL + "chatResp", conf)
                .then(res => { 
                    return res.json()})
                .then(
                    (result) => {
                        that.setState ({waitingForResponse:false, dialog:result.dialog, currentQuery:""})
                    },
                    (error) => {
                    }
                )
            /*
            const response = await fetch(GLOBAL_URL + 'chatResp', {
                method: "post",
                body: JSON.stringify({ "query": that.state.currentQuery, "docID": that.props.openDoc.ref }),
                headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
              });
            
            
            const reader = response.body.getReader();
            while (true) {
                const { value, done } = await reader.read();
                if (done || that.state.shouldCancel) 
                {
                    that.setState ({waitingForResponse:false, shouldCancel:false})
                    break;
                }
                try
                {
                    console.log (123, value)
                    let val = JSON.parse(new TextDecoder().decode(value).substring(5))
                    //console.log(val);
                    //document.getElementById ('outputContainer').innerHTML += val['token']['text']
                    if (val['generated_text'])
                        that.setState ({generatedText:val['generated_text']})
                    else
                        that.setState ({generatedText:that.state.generatedText + val['token']['text']})
                }
                catch (error) {
                    console.error(error);
                    // Expected output: ReferenceError: nonExistentFunction is not defined
                    // (Note: the exact output may be browser-dependent)
                  }
                
            }*/

        
    }

    render () {
        var that = this
        if (this.props.openDoc === null)
            return null

        var doc = this.props.openDoc

        
        return that.state.minimised ? <div className="ChatButton" role="button" onClick={(e)=>{that.setState({minimised:false})}}><ChatIcon/></div>: <div className={"ChatWindow "} >
            {that.state.dialog && (that.state.dialog.length > 0) ? 
                <div className="panel mb-3">
                    {that.state.dialog.map (function (di, idi) {
                        return <div key={"di" + idi} className="dialogItem">
                            <div className="role">{di.role === 'user' ? "You":di.role}</div>
                            <div className="message" dangerouslySetInnerHTML={{__html: di.content.replace ("\n", "<br/>")}}/>
                        </div>
                    })}
                    {that.state.generatedText}
                </div>:null}
            <div className="field">
                <div className="control">
                    <textarea
                        className="form-control"
                        rows={5}
                        placeholder="Type your question..."
                        value={this.state.currentQuery}
                        onChange={(e)=>{that.setState({currentQuery:e.target.value})}}
                        onKeyDown = {(e) => { if (e.keyCode == 13 && e.shiftKey == false) {
                            e.preventDefault();
                            that.handleSubmit (e);
                          } }}
                    />
                </div>
                <div className="buttons mt-3">
                    <Button variant="secondary" onClick={(e)=>{that.setState ({minimised:true})}}>Close</Button>
                    <Button variant="primary" className=' ms-auto' style={{opacity: that.state.waitingForResponse ? 0.5 : 1}} disabled={that.state.waitingForResponse} key="genPanel" onClick={(e)=>{that.handleSubmit(e)}}>Ask</Button>
                </div>
                
            </div>


        </div>
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        profile: state.data.profile,
        openDoc: state.data.openDoc,
        searchQuery: state.data.searchQuery
    }
}

export default connect(mapStateToProps)(ChatWindow)