import { Component } from "react";
import { connect } from 'react-redux'
import Table from 'react-bootstrap/Table';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';

import { apiRequest, MODIFY_DATA_PARAM } from "../redux/actionsAPI";
import { MODIFY_INTERFACE_PARAM } from "../redux/actions";
import Button from "react-bootstrap/esm/Button";

class MDInterface extends Component {
    state = {
        showLessRelevant: false,
        cfilters: {minDate:'', maxDate:''}
    }

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSelectChange = (e, inp) => {
        this.setState({ [inp.name]: e });
    };

    handleRefCBChange = e => {
        var cur = Object.assign ({}, this.state.unselectedRefs)
        cur[e.target.name] = !cur[e.target.name]
        this.setState ({unselectedRefs: cur})
    }




    cleanDateStringToFormatted (cds) {
        if (cds.length < 4)
            return cds
        if (cds.length < 6)
            return cds.substring(0,4) + '-' + cds.substring (4)
        return cds.substring(0,4) + '-' + cds.substring (4,6) + '-' + cds.substring(6)
    }

    cleanDateToTimestamp (cds, isBegin) {
        if (cds.length < 4)
            return null
        
        var cy = parseInt(cds.substring(0,4))
        if ((cds.length < 6) && (!isBegin))
            cy += 1
        
        var cm = 0
        if (cds.length >= 6)
        {
            cm = parseInt(cds.substring(4,6)) - 1
            if ((cds.length < 8) && (!isBegin))
                cm += 1    
            while (cm >= 12)
            {
                cy += 1
                cm -= 12
            }
        }

        var cd = 1
        if (cds.length === 8)
            cd = parseInt(cds.substring(6,8))
        var cdate = new Date(Date.UTC(cy, cm, cd, 0, 0, 0, 0))
        return cdate.getTime()
    }

    timestampToCleanDate (ts) {    
        if (!ts)
            return ""
        var d = new Date(ts);
        return d.getFullYear() +  ("0"+(d.getMonth()+1)).slice(-2) +  ("0" + d.getDate()).slice(-2)
    }

    setDatesFilter (params) {
        this.props.dispatch({type: MODIFY_DATA_PARAM, "paramName": "searchQuery", "paramValue": Object.assign({}, this.props.searchQuery, params)})
    }
    
    handleDateInputChange (e, dateType, isBegin=true) {
        var nval = e.target.value.replace(/\D/g,'').substring(0,8)
        if ((e.target.value.length < this.cleanDateStringToFormatted(this.state.cfilters[e.target.name]).length) && (nval === this.state.cfilters[e.target.name]))
            nval = nval.substring (0, nval.length-1)
        
        if (nval !== this.state.cfilters[e.target.name])
        {
            var nvalTS = this.cleanDateToTimestamp (nval, isBegin)
            var cParams = dateType === 'cdate' ? {minCDate:this.props.searchQuery.minCDate, maxCDate:this.props.searchQuery.maxCDate, permitEmptyCDate:this.props.searchQuery.permitEmptyCDate} : {minDate:this.props.searchQuery.minDate, maxDate:this.props.searchQuery.maxDate, permitEmptyDate:this.props.searchQuery.permitEmptyDate}
            if (nvalTS !== cParams[e.target.name])
            {
                cParams[e.target.name] = nvalTS
                if (dateType === 'cdate')
                    this.props.dispatch (setCDatesFilter(cParams))   
                else 
                    this.setDatesFilter(cParams) 
            }
            this.setState ({cfilters: Object.assign({}, this.state.cfilters, {[e.target.name]:nval, [e.target.name + "TS"]:nvalTS})})
            
        }
        
    }
    

    sameTSs (ts1, ts2) {
        if ((ts1 === null) || (ts1 === undefined))
            ts1 = -1
        if ((ts2 === null) || (ts2 === undefined))
            ts2 = -1
        return ts1 === ts2
    }

    syncDateValues (prevState) {
        var ts = Object.assign ({}, this.state.cfilters, {})
        var wereChanges = false

        var params = ["minDate", "maxDate", "minCDate", "maxCDate"]
        for (const dp of params)
         {
            if (!this.sameTSs (this.state.cfilters[dp + "TS"], this.props.searchQuery[dp]) && this.sameTSs (this.state.cfilters[dp + "TS"], prevState.cfilters[dp + "TS"]))
            {
                ts[dp] = this.timestampToCleanDate(this.props.searchQuery[dp])    
                ts[dp + "TS"] = this.props.searchQuery[dp]
                wereChanges = true
            }
        }
        if (wereChanges)
            this.setState ({cfilters:ts})
        
    }





    componentDidMount() {
        //this.props.dispatch (apiRequest({'endpoint': 'startSession'}))
    }

    componentDidUpdate (prevProps) {
        if (this.props.shouldPostSearch) {
            this.props.dispatch({type: MODIFY_DATA_PARAM, "paramName": "shouldPostSearch", "paramValue":false})
            this.props.dispatch (apiRequest({'endpoint': 'searchRequest', 'query':this.props.searchQuery}, null, false))
        }
        /*if (!prevProps.data.translatedQuery && this.props.data.translatedQuery) {
            this.setState ({chatQuery:this.props.data.translatedQuery})
            this.props.dispatch({type: MODIFY_DATA_PARAM, "paramName": "translatedQuery", "paramValue":null})
        }*/
    }

    onKeyUp(event) {
        if (event.keyCode === 13) {
            var that = this
            this.props.dispatch (apiRequest({'endpoint': 'chatRequest2', 
                                            'query':this.state.chatQuery, 
                                            'sessionID':this.props.data.sessionID, 
                                            'docRefs': this.props.data.docs.map (function (p) { if (!that.state.unselectedRefs[p.ref]) return p.ref; else return null }),
                                        }))
            //this.setState ({chatQuery:""})
        }
    }

    render () {
        var that = this

        var scoreT = 0.45
        var contextDocs = []
        var seenLessRelevant = false
        if (this.props.docs && (this.props.docs.length > 0))
            this.props.docs.map (function (p, ic) {
                var scoreIndicatorsNumber = 5
                var scoreIndicators = []
                for (var i = 0; i < scoreIndicatorsNumber; i += 1)
                    scoreIndicators.push (<span className={"scoreIndicator " + ((p.score * scoreIndicatorsNumber) > i ? "active":"")} key={"si"+p.id + 'p'+i}></span>  )

                

                if ((p.score > scoreT) || that.state.showLessRelevant) 
                    contextDocs.push ( <div key={"cDoc"+ic} className={"docCard" + ((that.props.openDoc?.id === p.id) ? " open":"") } role="button" onClick={(e)=>{ that.props.dispatch({type: MODIFY_INTERFACE_PARAM, "paramName": "browsingDocId", "paramValue":p.ref}) }}>
                        <div className="relevancy">{scoreIndicators}</div>
                        <h4 className="caption">{p.caption}</h4>
                        <div className="fields">
                            <div className="date">{p.dateString}</div>
                            <div className="source">{p.source.name}</div>
                            <div className="language">{p.language}</div>
                        </div>
                        
                    </div>)
                else
                {
                    if (! seenLessRelevant)
                        contextDocs.push (<Button className="lessRelevantButton" key="lrb" onClick={(e)=>{that.setState ({showLessRelevant:true})}}>Show less relevant docs</Button>)
                    seenLessRelevant = true
                }
            })
            
        
        return <div className={"MDInterface " + (that.props.openDoc ? "withDoc":"noDoc")} >
            <div className="SearchContainer">
                <div className="control mb-3">
                    <label htmlFor="searchQuery" className="form-label"><h4>Search</h4></label>
                    <textarea
                        className="form-control"
                        name="searchQuery"
                        required
                        onChange={(e)=>{this.props.dispatch({type: MODIFY_DATA_PARAM, "paramName": "searchQuery", "paramValue": Object.assign({}, this.props.searchQuery, {'query':e.target.value})})}}
                        value={this.props.searchQuery.query}
                        type="text"
                    />
                </div>
                    <label htmlFor="dates" className="form-label">Document date</label>
                    <div className="">
                        <div className="field datesContainer">
                            <div className="filter control withFloatingLabel mb-2">
                                <input
                                    className="input form-control"
                                    name="minDate"
                                    required
                                    onChange={(e)=>{that.handleDateInputChange(e, 'date');}}
                                    value={this.cleanDateStringToFormatted (this.state.cfilters.minDate)}
                                    autocomplete="off"
                                />
                                <span className="floating-label">From YYYY-MM-DD</span>
                            </div>
                            <div className="filter control withFloatingLabel mb-2">
                                <input
                                    className="input form-control"
                                    name="maxDate"
                                    required
                                    onChange={(e)=>{that.handleDateInputChange(e, 'date', false);}}
                                    value={this.cleanDateStringToFormatted (this.state.cfilters.maxDate)}
                                    autocomplete="off"
                                />
                                <span className="floating-label">To YYYY-MM-DD</span>
                            </div>
                        </div>    
                        <div className="mb-3">
                            <input checked={this.props.searchQuery.permitEmptyDate} type="checkbox" id="cbNoneDate" name="cbNoneDate" onChange={e => {that.setDatesFilter({minDate: that.props.searchQuery.minDate, maxDate: that.props.searchQuery.maxDate, permitEmptyDate: !that.props.searchQuery.permitEmptyDate})}}/>
                            <label htmlFor="cbNoneDate" className="ms-3">Include documents with no dates</label>
                        </div>
                    </div>
                <Button type="primary" disabled={(that.props.searchQuery.query.length < 5) } onClick={(e)=>{that.props.dispatch (apiRequest({'endpoint': 'searchRequest', 'query':that.props.searchQuery}, null, false))}}>
                    Search
                </Button>

            </div>
            
            {(contextDocs && (contextDocs.length > 0)) ? <div className="resultsContainer">
                <h5>Results {(that.props.searchQuery.startFrom > 0) ? <span> {that.props.searchQuery.startFrom + 1} - {that.props.searchQuery.startFrom + contextDocs.length}</span>:null}</h5>
                {contextDocs}
                
            </div>:null}

           


        </div>
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        profile: state.data.profile,
        docs: state.data.docs,
        openDoc: state.data.openDoc,
        shouldPostSearch: state.data.shouldPostSearch,

        searchQuery: state.data.searchQuery
    }
}

export default connect(mapStateToProps)(MDInterface)